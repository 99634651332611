.tag-container{
      padding : 0.5em 0.7em;      
      background-color : rgba(#5F8B9C,0.4);
      width : fit-content;
      margin : 0.5em;
      display: inline-block;
      box-shadow: 0 5px 5px -3px rgba(10, 14, 32, 0.38);

      .tag-text{
            color : #5F8B9C;
            font-weight: 500;
            font-size: 1.1em;
      }
}

