.intro {
      padding-top : 2em;
      text-indent : 2em;

      .photo-wrapper{
            padding : 1.5em;

            .photo{
                  margin: 0 auto;
                  background-color: white;
                  height: 25em;
                  max-height: 25em;
                  width: 100%;
                  max-width: 20em;
                  background-image: url("../pictures/photo.jpg");
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position-x: center;
                  background-position-y: center;
              
            }
      }

      .presentation{
            @media (min-width: 768px) {
                  padding  : 3em;
            }
            padding-top : 1em;
      }
}