#skills{
    padding-top : 2em;

    .logo-sql-server{
            height : 100px !important;
      }

      .logo{
            height : 60px;
            margin : 2em;
      }

      .other-skills-title{
            margin : 2em 0;
      }

      h3{
            font-weight: 400;
            font-size: 1.2em;
      }

      .cat{
            color : #627f99;
            font-weight: 500;
            font-size: 1.2em;
            margin-bottom : 0.5em
      }
}