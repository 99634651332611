@import './Global.scss';

.body{
  background-color: whitesmoke;
  height: 100%;
}

.cs-block{
  background-color : white;
  margin : 1em;
  margin-top : 2.4em;
  text-align: center;
  box-shadow : $shadow;


  h1{
    font-size: 1.8em;
  }
}

.title{
  margin-bottom : 2.5em;
  margin-top : 1.5em;
}



  .cache-misere-top{
    top : 240px;   
    float: right;
    position: relative;
    border-top : 80px solid transparent;
    border-left : 30px solid transparent;
    border-right : 30px solid white;    
    border-bottom : 80px solid white;
    z-index: 1000;
  }
  
  .cache-misere-bottom{
    width: 0px;
    position: relative;
    border-top : 80px solid  white;
    border-left : 30px solid white;
    border-right : 30px solid  transparent;
    border-bottom : 80px solid  transparent;
    z-index: 1000;
  }

  .title-overlay{
    min-height : 4em;
    position: relative;
    width:100%;
    .overlay{     
      width : 100%;
      background: linear-gradient(140deg,$blue 40%, green 90%);
      opacity: 0.5;
      height : 100%;
      position: absolute;
    }

    .text{
      font-weight: 100;
      padding : 0.5em;
      font-size: 2em;
      color : #4f6560;
      position: relative;
      z-index: 1000;
    }
}
.pd-3{
  padding : 3em;
}

.pd-2{
  padding : 2em;
}

.pd-1{
  padding : 1em;
}

button:focus{
  outline : 0;
}

// SCROLLBAR
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: whitesmoke; 
}
 
::-webkit-scrollbar-thumb {
  background : #3e6282;
  // border-radius : 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}