@import './Global.scss';

.header {
      height: 2.4em;
      width: 100%;
      position: absolute;
      color : white;
      font-size: 1.2em;
      font-weight: 100;
      z-index: 1001;
      background-color : #3e6282e8;
     // opacity: 0.9;
      position: fixed;

     .item {
            cursor:pointer;
            color: white;
            background-color : rgba(#3e6282e8,0);
            border : 0;
            height : 100%;
            width : 10em;

            &:hover{
                  background-color:  #83C188;
            }

          
      }

      .header-bar{
            height : 100%;
      }
}
