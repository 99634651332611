@import './Global.scss';

.header-profile{
    color: white;
    background-color : $blue;
    box-shadow:0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    background-image: url("../pictures/computer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    height  : 100vh;
    position : relative;

    .text{
      padding : 25px;
      margin-top : 25vh;
    }

    .overlay{
      height : 100vh;
      width : 100%;
      background: linear-gradient(140deg,$blue 40%, green 90%);
      opacity: 0.5;
      position: absolute;
    }
  }
  
  
  .name{
    font-size : 4em;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
  }
  
  .infos{      
    font-size: 1.2em;
    width : fit-content;
    margin : 0 auto;
  }

  .remote{    
    color : $malt-pink;
    border : 2px solid $malt-pink;
    background-color : white;
    border-radius: 15px;
    padding : 5px 10px;
    top : 4em;
    left : 0.4em;

    -webkit-transform: rotate(-30deg);
    -webkit-transform-origin: left top;
    -moz-transform: rotate(-30deg);
    -moz-transform-origin: left top;
    -ms-transform: rotate(-30deg);
    -ms-transform-origin: left top;
    -o-transform: rotate(-30deg);
    -o-transform-origin: left top;
    transform: rotate(-30deg);
    transform-origin: left top;
    position: absolute;
  }
  
  .email {
    margin-top : 30px;
  }
  
  .job{
    font-size: 1.7em;
  }    

  .links{
    margin-top : 40px;
    background-color : white;
    width : fit-content;
    border-radius: 15px;
    height : 60px;
  }
  
  
  .link-icon{
    height : 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color:transparent;
    background-position-y: center;
    background-color : white;
    border-radius: 5px;
    display: inline-block;
    margin : 15px;
  }

  .linkedin{    
    background-image: url("../pictures/linkedin.png");
  }

  .malt{    
    background-image: url("../pictures/malt.png");
  }
  
  .tiret {
    position: relative;
    top : -40px;
    left : -3px;
    height : 40px;
    margin:  0 auto;
    width : 4px;
    border : 4px dotted  gray;
    border-left: none;
    margin-bottom: -80px;
  }

  .btn-next{
    font-size: 2em;
    border : 1px solid white;
    border-radius: 100%;
    width : 2em;
    height : 2em;
    text-align: center;
    cursor: pointer;
    background-color : rgba(white,0.20);
    right : 0;
    left : 0;
    margin-right : auto;
    margin-left : auto;
    width: 2em;
    position: absolute;
    bottom : 2%;
    
  }