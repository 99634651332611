@import './Global.scss';

.footer{
    background-color: white;
    position: relative;
    

    .footer-background{
        background-image: url("../pictures/keyboard.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height : 14em;
        width : 100%;
        position: absolute;
    }

    .overlay{
        min-height : 14em;
        width : 100%;
        background: linear-gradient(140deg,$blue 40%, green 90%);
        opacity: 0.5;
        position: absolute;
        z-index: 1;
        height : 100%
      }

      .text{
        position: relative;
        z-index: 100;
        color: white;
        font-weight: 500;
        padding : 1em;

        .copyright{
            padding : 1em;
        }

        .legal{
            padding : 1em;

            .legal-text{
                background-color: rgba($blue, 0.4);
                padding: 1em;
                float: right;

                .legal-title{
                    font-weight: 300;
                    font-size: 1.2em;
                }
            }
        }
      }

    
}