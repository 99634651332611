@import './Global.scss';


.services   {
      
      padding-top : 2em;
      padding-bottom : 2em;

      .subtitle{
            font-size: 1.2em;
            padding : 0.5em;
            font-weight: 600;
            color : #627f99;
      }

      .service{
            @media (min-width: 768px) {
                  margin-left : 2em;                  

                  .description{
                        padding-left : 2em;
                  }
            }
      }

      li{
            list-style-type: square;
            color: cadetblue;      
      }

      .btn-contact-me{
            color : green;
            font-weight: 600;
            text-decoration: underline;
            text-decoration-line: 10px;
      }


      .work-together-container{
            padding : 2em;

            .work-together{
                  height: 80%;
                  width: 80%;
                  background-image: url(/pictures//work-together.png);
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position-x: center;
                  background-position-y: center;
                  margin : 0 auto;
                  margin-top: 4em;
            }
      }
}

