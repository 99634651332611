@import './Global.scss';

.project-card{
      color : white;
      font-weight: 500;
      font-size : 1em;
      padding : 1em;
      box-shadow : $shadow;
      height : 6em;
      width: 100%;
      text-align: left;
      align-items: center;
      background-color : rgba(#5F8B9C,0.4);
      color : #5F8B9C;
      float:right;
      cursor : pointer;

      &.selected{
            background-color: #83C188;
            color : white;
      }

      &:hover{
            background-color: rgba(#83C188,0.75);
      }    

      .text{
            padding-top : 1.2em;
            font-size: 1.2em;
            float:left; 
      }

}
