@import './Global.scss';


#project{
   
      padding-bottom : 4em;

      .pd0{
            padding :0 !important;
      }
}

#project-description{
      min-height : 20em;
      padding : 2em;
      text-align: left;
      color : #3e6282e8;
}